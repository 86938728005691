import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// UBO
import Plaatjie from '@ubo/plaatjie'

// Images
import FingerPrint from 'components/elements/FingerPrint'

const GalleryWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  @media (min-width: 768px) {
    grid-auto-rows: 320px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 767px) {
    grid-auto-rows: 150px;
    grid-template-columns: repeat(2, 1fr);
  }
`

const Image = styled(Plaatjie)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Row = styled.div<{ bgColor: string }>`
  ${(props) =>
    props.bgColor === 'contrast' &&
    css`
      background: rgb(${props.theme.color.gradient.contrast.to});
      background: linear-gradient(
        135deg,
        rgba(${props.theme.color.gradient.contrast.from}, 1) 0%,
        rgba(${props.theme.color.gradient.contrast.to}, 1) 45%
      );
    `}
  ${(props) =>
    props.bgColor === 'default' &&
    css`
      background: ${props.theme.color.background};
    `}
  border-radius: 41px;
  z-index: 80;

  & p {
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-family: ${({ theme }) => theme.font.family.main};
  }

  @media (min-width: 576px) {
    margin-top: -7.5rem;
  }
  @media (max-width: 575px) {
    margin-top: -2.3rem;
  }

  & iframe {
    @media (min-width: 992px) {
      min-height: 360px !important;
    }
    @media (max-width: 991px) {
      min-height: 300px !important;
    }
  }
`

const ContentBackground = styled.div`
  top: -280px;
  left: 55%;

  right: 0;
  & svg {
    opacity: 0.12;

    @media (min-width: 992px) {
      width: 500px;
    }

    @media (max-width: 991px) {
      width: 600px;
      top: -150px;
      left: 45%;
    }

    @media (max-width: 767px) {
      width: 400px;
      top: -250px;
      left: 30%;
    }
  }
`

const DescriptionContent = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 45px;
  }
`

interface GalleryProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Gallery
  location?: any
}

const Content = styled(ParseContent)`
  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: space-between;
  }
`

const Gallery: React.FC<GalleryProps> = ({ fields }) => (
  <section className="mb-lg-5 mb-3">
    <GalleryWrapper>
      {fields?.images?.map((image, index: number) => (
        <Image
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          image={image?.image}
          className={`gallery-item-${index}`}
          alt="Gallerij afbeelding"
        />
      ))}
    </GalleryWrapper>
    <div className="container">
      <div className="row justify-content-center px-4 px-sm-0">
        <div
          className={`${
            fields?.content?.background === 'contrast'
              ? 'col-md-12'
              : 'col-md-9'
          } position-relative`}
        >
          <Row
            bgColor={fields?.content?.background || 'default'}
            className="row align-items-center position-relative"
          >
            {fields?.content?.cta && (
              <div className="col-lg-4">
                <Content
                  content={fields?.content?.cta}
                  className="px-4 px-lg-5 pt-lg-5 pb-lg-5 pt-4 pb-3 d-flex flex-column flex-sm-row flex-lg-column"
                />
              </div>
            )}

            <div className={`${fields?.content?.cta ? 'col-lg-8' : 'col-12'}`}>
              <DescriptionContent
                content={fields?.content?.description}
                className="px-lg-5 px-sm-4 px-2 pl-lg-2 pr-lg-5 pb-lg-5 pb-3 pt-lg-5 pt-3"
              />
            </div>
          </Row>
          {fields?.content?.background === 'contrast' && (
            <ContentBackground
              className="position-absolute"
              style={{ zIndex: 80 }}
            >
              <FingerPrint />
            </ContentBackground>
          )}
        </div>
      </div>
    </div>
  </section>
)

export default Gallery
